/* reset */
.adjust {
    .ToolbarToggleItem,
    .ToolbarLink,
    .ToolbarButton {
        cursor: pointer;
        flex: 0 0 auto;
        color: $clr2;
        padding: $spacing-xxs;
        border: 1px solid $editor-lightgray;
        border-radius: 4px;
        display: inline-flex;
        line-height: 1;
        align-items: center;
        justify-content: center;
        transition: 0.3s cubic-bezier(0.25, 1, 0.5, 1) background-color, color 0.3s cubic-bezier(0.25, 1, 0.5, 1);
    }
    .ToolbarToggleItem:hover,
    .ToolbarLink:hover,
    .ToolbarButton:hover {
        background-color: $editor-lightgray;
    }
    .ToolbarToggleItem:focus,
    .ToolbarLink:focus,
    .ToolbarButton:focus {
        position: relative;
    }

    .ToolbarToggleItem {
        margin-inline-start: $spacing-xxs;
    }
    .ToolbarToggleItem:first-child {
        margin: 0;
    }
    .ToolbarToggleItem[data-state='on'] {
        background-color: $editor-active-bg;
        color: $editor-active-clr;
    }

    .ToolbarSeparator {
        width: 1px;
        background-color: $editor-lightgray;
        margin: 0 0;
    }
}
.text-decoration {
    button {
        padding: 0;
        background: #eeeeee;
        border-radius: 5px;
        padding: 0.3rem 0.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    display: flex;
    justify-content: space-between;
}

.color-pick {
    position: relative;
    .curr-color {
        cursor: pointer;
        margin-inline-start: auto;
        justify-self: end;
        width: 22px;
        height: 22px;
        background-color: #000;
        border-radius: 4px;
        border: 1px solid $editor-lightgray;
    }

    .block-picker {
        > *:last-child {
            background: white;
            box-shadow: 0px 20px 30px -20px rgba(0, 0, 0, 0.5);
        }
        background: white !important;
        padding: 0;

        opacity: 0;
        transition: all 1s cubic-bezier(0.16, 1, 0.3, 1);
        transform: translate(-100%);
    }
    .block-picker.open {
        transform: translate(0%);
        padding: 0;
        height: 0;
        opacity: 1;
    }
    .palette {
        z-index: 3;
        height: 0;
    }
}
