$text-1: #333;
$text-2: #666;
$text-3: #999;
$line: #ccc;
$time-bg: #eee;
$background: #f7f7f7;

.messages-dashboard {
    display: grid;
    grid-template-columns: minmax(auto, 1fr) 4fr;
    flex: 1;
    background-color: darken($editor-lightgray, 11%) !important;
    overflow: hidden;
    box-shadow: inset 3px 3px 20px -10px rgba($clr7, 90%) !important;
    border-radius: 12px;
    .pic {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        background-size: cover;
        background-position: center;
        border-radius: 50%;

        &.bigger {
            width: 3rem;
            height: 3rem;
        }
    }

    .chat {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        z-index: 2;
        box-sizing: border-box;
        border-radius: 0 $spacing-s $spacing-s 0;

        .chat-layout {
            height: 100%;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }

        .contact.bar {
            display: flex;
            gap: 1em;
            flex-shrink: 0;
            padding: $spacing-s $spacing-m;
            box-sizing: border-box;
            border-bottom: 1px solid $editor-lightgray;

            font-weight: 700;
            color: rgba(37, 37, 54, 0.918);
            border-bottom: 1px solid rgba(68, 68, 100, 0.17);
            background-color: rgba(255, 255, 255, 0.884);

            backdrop-filter: blur(5px);
            .name {
                font-weight: 600;
                font-size: 1rem;
            }
            .seen {
                color: rgba(37, 37, 54, 0.918);
            }
        }

        .messages {
            padding: 1em;
            box-shadow: inset 3px 3px 20px -10px rgba($clr7, 90%);
            background: #bcbcd1;
            flex-grow: 2;
            overflow-y: auto;
            .time {
                font-size: 0.8rem;
                background: linear-gradient(200deg, #34446757, lighten($clr7, 1%));
                padding: 0.25rem 1em;
                opacity: 0.55;
                border-radius: 2rem;
                color: $clr1;
                width: fit-content;
                margin: 0 auto;
            }

            .message {
                box-sizing: border-box;
                padding: 0.5em 1em;
                margin: $spacing-m 1em;
                background: $clr1;
                border-radius: 1.125rem 1.125rem 1.125rem 0;
                min-height: 2.25rem;
                width: fit-content;
                box-shadow: 0 0 2rem rgba($clr2, 0.075), 0rem 1em 1em -1em rgba($clr2, 0.1);

                &.owner {
                    margin: 1em 1em 1em auto;
                    border-radius: 1.125rem 1.125rem 0 1.125rem;
                    background: darken($clr7, 5%);
                    color: $clr1;
                }

                &.parker {
                    margin: 1em 1em 1em auto;
                    border-radius: 1.125rem 1.125rem 0 1.125rem;
                    background: $text-1;
                    color: $clr1;
                }

                .typing {
                    display: inline-block;
                    width: 0.8rem;
                    height: 0.8rem;
                    margin-right: 0rem;
                    box-sizing: border-box;
                    background: #ccc;
                    border-radius: 50%;

                    &.typing-1 {
                        animation: typing 3s infinite;
                    }
                    &.typing-2 {
                        animation: typing 3s 250ms infinite;
                    }
                    &.typing-3 {
                        animation: typing 3s 500ms infinite;
                    }
                }
            }
        }

        .input {
            box-sizing: border-box;
            flex-basis: 4rem;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            padding: 0 $spacing-s 0 $spacing-m;
            border-top: 1px solid rgba(218, 218, 230, 0.918);
            background-color: rgba(255, 255, 255, 0.884);
            i {
                color: $text-2;
                cursor: pointer;
                transition: color 200ms;

                &:hover {
                    color: $text-1;
                }
            }

            input {
                background-image: none;
                background-color: darken($clr1, 7%);
                padding: 0.5em 1em;
                margin-right: 1em;
                border-radius: 1.125rem;
                flex-grow: 2;
                font-weight: 400;
                letter-spacing: 0.015rem;
                &:placeholder {
                    color: $text-3;
                }
            }
        }
    }

    @keyframes typing {
        0%,
        75%,
        100% {
            transform: translate(0, 0.25rem) scale(0.9);
            opacity: 0.5;
        }

        25% {
            transform: translate(0, -0.25rem) scale(1);
            opacity: 1;
        }
    }

    // Obviously in a real app I would load this from a DB with JS
    // For now this is a nice quick and easy method to mockup

    .pic.stark {
        background-image: url('https://vignette.wikia.nocookie.net/marvelcinematicuniverse/images/7/73/SMH_Mentor_6.png');
    }
    .pic.guest {
        // background-image: url('https://res.cloudinary.com/yaronshapira-com/image/upload/v1674562734/no-profile-pic-icon-11_gp6obg.jpg');
        background-color: #761ba4;
        color: #eee;
    }
    .pic.banner {
        background-image: url('https://vignette.wikia.nocookie.net/marvelcinematicuniverse/images/4/4f/BruceHulk-Endgame-TravelingCapInPast.jpg');
    }
    .pic.thor {
        background-image: url('https://vignette.wikia.nocookie.net/marvelcinematicuniverse/images/9/98/ThorFliesThroughTheAnus.jpg');
    }
    .pic.danvers {
        background-image: url('https://vignette.wikia.nocookie.net/marvelcinematicuniverse/images/0/05/HeyPeterParker.png');
    }
    .pic.rogers {
        background-image: url('https://vignette.wikia.nocookie.net/marvelcinematicuniverse/images/7/7c/Cap.America_%28We_Don%27t_Trade_Lives_Vision%29.png');
    }

    .send-btn {
        margin-right: 0.2rem;
        cursor: pointer;
    }
}
