.compose-mail {
    position: fixed;
    bottom: 0%;
    right: 0%;
    border-radius: $spacing-s 0 0 0;
    width: 500px;
    background-color: $clr1;
    box-shadow: 0px 0px 50px -20px rgb(0 0 0 / 20%);
    display: flex;
    flex-direction: column;
    .weight-700 {
        font-weight: 700;
    }
    .close-btn-wrapper {
        padding: 1em 1.5em 0;
        text-align: end;
    }

    .to {
        padding: $spacing-s $spacing-m;
        display: grid;
        grid-template-columns: 50px 1fr;
        border-bottom: 1px solid rgb(231, 231, 231);
        pointer-events: none;
    }
    .title {
        padding: $spacing-s $spacing-m;
        display: grid;
        grid-template-columns: 50px 1fr;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid rgb(231, 231, 231);
    }
    input {
        padding: 0rem;
        background: transparent;
    }
    textarea {
        padding: 0 $spacing-m;
        width: 100%;
        height: 100%;
        border: none;
        background-color: transparent;
    }
    textarea:focus {
        border: none;
        outline: none;
    }
    .mail-container {
        background-color: $clr1;
        padding: $spacing-s 0 1em;
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .actions {
        display: flex;
        justify-content: space-between;
        .send {
            align-self: flex-end;
            padding: 0 $spacing-m 0 0;
            button {
                cursor: pointer;
                padding: 2px 8px;
                border-radius: 3px;
                color: $clr1;
                background-color: $editor-active-clr;
                transition: background-color 0.45s cubic-bezier(0.075, 0.82, 0.165, 1),
                    color 0.45s cubic-bezier(0.165, 0.84, 0.44, 1), box-shadow 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
            }
            button:hover {
                background-color: darken($editor-active-clr, 15%);
            }
        }
    }
}
