.publish-modal {
    position: fixed;
    inset: 0;
    margin: auto;
    background-color: rgba(0, 6, 36, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 989919989;
    pointer-events: none;
    opacity: 0;
    transition: 0.55s opacity cubic-bezier(0.075, 0.82, 0.165, 1);
    &.open {
        pointer-events: all;
        transition: 0.25s opacity cubic-bezier(0.075, 0.82, 0.165, 1);
        opacity: 1;
        .container {
            opacity: 1;
            transition: 0.35s all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
            transform: translateY(-0%);
        }
    }
    .container {
        opacity: 0;
        transition: opacity 0.25s, 0.65s transform cubic-bezier(0.075, 0.82, 0.165, 1);
        transform: translateY(-20%);
        background-color: $clr1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 3px;
        padding: $spacing-m;

        @media (min-width: 440px) {
            padding: $spacing-l;
        }

        position: relative;
        .content-container {
            width: 440px;
            max-width: 440px;
            min-height: 30vh;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 1.5em;
            .TabsRoot {
                width: 100%;
            }
            .where-next-btns {
                display: flex;
                gap: $spacing-m;
                button.app-btn.secondary {
                    color: $editor-active-clr;
                }
            }
            .intro-text {
                gap: $spacing-s;
            }
        }
        .close-btn {
            cursor: pointer;
            position: absolute;
            top: $spacing-xs;
            right: $spacing-s;
        }
        h4 {
            font-weight: 800;
            text-align: center;
            font-size: px-to-rem(20px);
            @media (min-width: 440px) {
                font-size: px-to-rem(24px);
            }
        }
        p {
            font-size: px-to-rem(12.44px);
            color: darken($clr9, 10%);
            text-align: center;
            @media (min-width: 440px) {
                font-size: px-to-rem(16px);
            }
        }

        button {
            align-self: center;
            &.app-btn.primary > * {
                color: $clr1;
            }
            &.app-btn.secondary > * {
                color: $editor-active-clr;
            }
        }

        h5.secondary-title {
            font-size: 1.125em;
            color: lighten($clr2, 25%);
            font-weight: 600;
        }
        .bold {
            margin: 0 !important;
            font-weight: 700;
        }
        .seperator {
            height: 1px;
            width: 80%;
            margin: 0.5em auto;
            background-color: #e9e9e9;
        }

        .site-img {
            aspect-ratio: 16/9;
            max-height: 75px;
            border-radius: 0.5em;
            box-shadow: 0px 2px 6px -3px $clr9;
            overflow: hidden;
        }
    }
}
.free-domain {
    width: 100%;

    max-width: 380px;

    h5 {
        font-size: 1.125rem;

        margin-bottom: 0.75em;
        text-align: center;
    }
}
.link-preview-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 5px;
    background: #eee;
    padding: 6px;
    border-radius: 3px;

    .dots {
        margin: 01em;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        span {
            cursor: alias;
            background-color: white;
            border-radius: 50px;
            display: inline-block;
            width: 8px;
            height: 8px;
        }
    }
}
.link-preview {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background: #fff;
    border-radius: 3px;
    &.site-name {
        display: grid;
        gap: 5px;
        padding: 0.5em 1em;
    }
    input {
        background-color: #fff;
        box-shadow: inset 0px 0px 0px 1px rgb(17 109 255);
        &::selection {
            color: $editor-active-clr;
            background: $editor-active-bg;
        }
        &::-moz-selection {
            /* Code for Firefox */
            color: $editor-active-clr;
            background: $editor-active-bg;
        }
    }
    span {
        width: 100%;
        flex: 1;
        margin-inline-start: 0.5em;
    }
}
