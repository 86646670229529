.editor {
    border-radius: $spacing-s;
    padding: $spacing-s;
    max-width: 100%;
    margin-top: $spacing-l / 2;
    min-height: calc(100vh - $app-header-height - $toolbar-height - $spacing-l);
    max-height: calc(100vh - $app-header-height - $toolbar-height);
    background-color: lighten($editor-lightgray, 5%);
}

.clicked {
    position: relative;

    outline: solid 2px $editor-outline !important;

    &.no-click {
        outline: unset !important;
    }
}

.map.no-click {
    * {
        outline: unset !important;
    }
}
.video-disabled {
    pointer-events: none;
}
.selected-actions {
    position: absolute;
    z-index: 1;

    padding: 1rem 1rem 0.7rem 1rem;

    .mini-wrapper {
        color: white;
        cursor: pointer;
        background: $editor-outline;
        color: $editor-active-bg;
        padding: 0.3rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.hover {
    background: rgba(7, 57, 110, 0.2);
    outline: solid 2px $editor-outline;
}
.save-msg {
    background-color: $clr1;
    padding: 2px 8px;
    margin-inline-start: 1em;
    border-radius: 3px;
    position: relative;
    opacity: 0;
    transform: translateX(calc(100% - 16px));
    z-index: -1;
    transition: 0.25s all ease-out;
    color: $clr7;
    margin-inline-start: auto;
    .refresh-icon {
        margin-inline-end: 0.5em;
        color: $clr7;
        animation: infinite spin 0.7s linear;
        @keyframes spin {
            from {
                transform: rotateZ(180deg);
            }
            to {
                transform: rotateZ(0deg);
            }
        }
    }
    &.shown {
        transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
        opacity: 1;
        transform: translateX(0%);
    }
}

.mouse-pos {
    position: absolute;
    display: none;
    width: 30px;
}
