.home-header {
    background-color: $clr1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    z-index: 99;
    box-shadow: 0px -10px 20px 10px rgba($clr2, 25%);
    padding-left: $layoutPadding;
    position: sticky;
    top: 0;
    font-size: px-to-rem(16px);

    & .btn-mobile-menu {
        cursor: pointer;
        height: $home-header-height;
        line-height: $home-header-height;
        padding: 0 2em;
        background-color: $clr5;
        color: $clr1;
        transition: background-color 0.2s;

        &:hover {
            background-color: darken($clr5, 10%);
        }
    }
    .logo-container {
        max-width: 90px;
    }

    
    & .main-nav {
        position: static;
        transform: unset;
        background-color: unset;
        min-height: unset;
        min-width: unset;
        padding: 0;

        & ul {
            display: flex;
            flex-direction: row;
            gap: unset;
        }

        & ul li {
            display: flex;
            height: 100%;
        }

        & .nav-link {
            height: $home-header-height;
            line-height: $home-header-height;
            padding: 0 2em;
            font-weight: 500;
            margin-inline-start: 2em;

            &.btn-start-now {
                background-color: $clr5;
                color: $clr1;
            }

            &.btn-start-now:hover {
                background-color: darken($clr5, 10%);
            }
        }
    }
    @media (min-width: $break-narrow) {
        & .main-nav {
            position: static;
            transform: unset;
            background-color: unset;
            min-height: unset;
            min-width: unset;
            padding: 0;

            & ul {
                display: flex;
                flex-direction: row;
                gap: unset;
            }

            & ul li {
                display: flex;
                height: 100%;
            }

            & .nav-link {
                height: $home-header-height;
                line-height: $home-header-height;
                padding: 0 2em;
                font-weight: 500;
                margin-inline-start: 2em;

                &.btn-start-now {
                    background-color: $clr5;
                    color: $clr1;
                }

                &.btn-start-now:hover {
                    background-color: darken($clr5, 10%);
                }
            }
        }

        & .btn-mobile-menu {
            display: none;
        }
    }
}
.quix-logo {
    width: 100%;
}
