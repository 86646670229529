.app-header {
    background-color: $clr1;
    position: sticky;
    top: 0;
    z-index: 99;
    .icons-group.mobile-only {
        align-self: stretch;
        display: flex;
        justify-content: center;
        .icon-container {
            height: 100%;
            width: max-content;
            padding: 0 0.5em;
        }
    }
    .logo-container {
        min-width: 85px;
        max-width: 85px;
        .quix-logo {
            width: 100%;
        }
    }
    &.auth {
        .logo {
            position: relative;
            top: $spacing-s;
        }
        .logo-container {
            max-width: 120px;
        }
    }
    &.dark {
        background-color: $clr2;
        .layout-wrapper {
            grid-template-columns: auto 1fr;
        }
        .user-area {
            margin-inline-start: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            .avatar {
                box-shadow: 0px 0px 0px 0px $editor-active-clr;
            }
        }
        & *,
        & a {
            fill: $clr1 !important;
            .logo-container {
                max-width: 90px;
            }
        }
    }
    label,
    input {
        display: inline-flex;
        align-items: center;
    }
    .layout-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        margin: auto;
        z-index: 9999;
        border-bottom: 1px solid $editor-lightgray;
        padding: 0 1em;
        min-height: $app-header-height;
        @media (min-width: 960px) {
            padding: 0 $spacing-m;
        }
    }
    &.main-layout {
        .layout-wrapper {
            min-height: $app-header-height + 1px;
            padding: 0;
            border-bottom: none;
        }
        .logo-container {
            font-size: px-to-rem(24px);
            border: none;
        }
    }
    .logo-container {
        border-inline-end: 1px solid $editor-lightgray;
        padding-inline-end: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: px-to-rem(20px);
        .logo {
            cursor: pointer;
            font-weight: 600;
            letter-spacing: -0.02rem;
        }
    }

    .sites-actions {
        padding: 0 $spacing-m;
        flex: 1;
        display: flex;
        align-items: center;
        gap: 1em;
        .sites-actions-dropdown {
            cursor: pointer;
        }
    }
    .user-actions {
        .nav-link {
            font-weight: 400;
            margin-inline-start: $spacing-s;
            border-radius: 0.25rem;
            padding: $spacing-xxs $spacing-xs;
            transition: color 0.3s, background-color 0.2s;

            &.publish {
                background-color: $editor-active-clr;
                color: $clr1;
                display: inline-flex;
                align-items: center;
                box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
                padding: $spacing-xxs 1em;
                border-radius: 4px;
                border: 1px solid transparent;
                font-size: 14px;
                font-weight: 600;
                &:hover {
                    background-color: darken($editor-active-clr, 10%);
                }
            }
            &.preview {
                cursor: pointer;
                background-color: transparent;
                position: relative;
                padding: $spacing-xxs $spacing-xs;
                color: $editor-active-clr;
                transition: color 0.14s;
                &:hover {
                    transition: color 0.14s;
                    color: darken($editor-active-clr, 5%);
                }

                &::before {
                    content: '';
                    position: absolute;
                    inset: 0;
                    margin: auto;
                    border-radius: $spacing-xxs;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    transform: scale(0.5);
                    transition: all 0.3s cubic-bezier(0.83, 0, 0.17, 1);
                }
                &:hover {
                    &::before {
                        transform: scale(1);
                        color: lighten($clr2, 10%);
                        background-color: darken($clr1, 3%);
                    }
                }
            }
        }
    }
    .main-nav {
        height: 100%;
        display: flex;
        align-items: stretch;
        .nav-link {
            margin-inline-start: 1em;
        }
    }
}
.app-header[data-location='editor'] {
    .site-link,
    .user-actions ul li {
        display: none;
    }
}
.user-area {
    display: flex;
    align-items: center;
    justify-content: center;
    .avatar {
        cursor: default;
        width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 55px;
        overflow: hidden;
        color: $clr1;
        text-transform: uppercase;
        background-color: scale-color($color: #055345, $saturation: -20%, $lightness: -5%);
        box-shadow: inset 0px 0px 0px 0px rgba($clr19, 50%);
        transition: 1s ease-out box-shadow;
        box-shadow: 0px 0px 0px 2px scale-color($color: $editor-active-clr, $saturation: -20%, $lightness: 35%);

        &:hover {
            transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) box-shadow;
            box-shadow: 0px 0px 0px 2.5px lighten($editor-active-clr, 5%);
        }
    }
    .user-info {
        background-color: darken($clr1, 5%);
        padding: 2px 4px;
        border-radius: 3px;
        line-height: 1.125;
        cursor: default;
        font-weight: 600;
        display: grid;
        place-items: center start;
        margin-inline-start: 0.5em;
        
        .user-links {
            cursor: pointer;
            & > * {
                font-size: px-to-rem(12.414px);
                transition: color 0.18s;
                color: $clr9;
                &:hover {
                    color: $editor-active-clr;
                }
            }
            .btn-dashboard {
                color: $clr20;
                &:hover {
                    color: $editor-active-clr;
                }
            }
        }
        .sign-in,
        .sign-up {
            font-weight: 600;
        }
        .btn-logout {
            margin-inline-start: 0.4em;
        }
    }
}

.interactives > * {
    height: 100%;
}
div[data-radix-popper-content-wrapper] {
    z-index: 99 !important;
}
@media (min-width: 960px) {
    .app-header[data-location='editor'] {
        .icons-group.mobile-only {
            display: none;
        }
        .user-area,
        .site-link,
        .user-actions ul li {
            display: flex;
        }
    }
}
