.main-layout {
    display: grid;
    grid-template-columns: $layoutPadding 1fr $layoutPadding;
    place-content: baseline;
    > * {
        grid-column: 2 / -2;
    }
    > *.full {
        grid-column: 1 / -1;
    }
    .side-start {
        grid-column: 1/-2;
    }

    .side-end {
        grid-column: -1/2;
    }

    @include for-mobile-layout {
        @include layoutGTC($padded-break-mobile);
    }

    @include for-narrow-layout {
        @include layoutGTC($padded-break-narrow);
    }

    @include for-wide-layout {
        @include layoutGTC($padded-break-wide);
    }
}

.main-text {
    margin-bottom: px-to-rem(30px);
}

.editor-layout {
    max-height: calc(100vh - $app-header-height + $toolbar-height);
    display: flex;
    overflow: hidden;
    .editor-preview {
        flex: 1;
        height: calc(100vh - $app-header-height - $toolbar-height);
    }

    
    .side-start {
        grid-column: 1/-2;
    }

    .side-end {
        grid-column: -1/2;
    }


}
@media (min-width: 960px) {
    .editor-layout .editor-preview {
        padding: 1.5em 3.375em;
    }
}
