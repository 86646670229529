/* reset */

.SelectTrigger {
    cursor: pointer;
    z-index: 2;
    background-color: $editor-lightgray;
    color: $clr7;
    padding: 0 $spacing-xxs;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: $spacing-xxs;
    transition: background-color 0.3s, color 0.3s;
    

    &[data-placeholder] {
        color: $clr7;
        &:hover {
            background-color: lighten($editor-lightgray, 5%);
            color: $clr7;
        }
    }
}

.SelectContent {
    overflow: hidden;
    z-index: 9999;
    pointer-events: all;
    background-color: $clr1;
    border-radius: 6px;
    box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
}

.SelectViewport {
    padding: $spacing-s;
}

.SelectItem {
    outline: none;
    font-size: px-to-rem(12.44px);
    color: $clr2;
    border-radius: 3px;
    display: flex;
    align-items: center;
    height: $spacing-m;
    padding: 0 $spacing-xs 0 $spacing-m;
    position: relative;
    user-select: none;
    transition: 0.2s all;
}
.SelectItem[data-state='checked'] {
    color: $editor-active-clr;
    background-color: $editor-active-bg;
    pointer-events: none;
}
.SelectItem[data-disabled] {
    color: $clr7;
    pointer-events: none;
}
.SelectItem[data-highlighted] {
    outline: none;
    background-color: $editor-hover-bg;
}

.SelectSeparator {
    height: 1px;
    background-color: $editor-active-bg;
    margin: $spacing-xxs;
}

.SelectItemIndicator {
    position: absolute;
    left: 0;
    width: $spacing-m;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.SelectScrollButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $spacing-m;
    background-color: $clr1;
    color: $clr7;
    cursor: default;
}

// OPEN STYLE SELECT
.site-select {
    height: 100%;
    .open-style-select {
        &.SelectTrigger {
            all: unset;
            padding: 0 $spacing-m;
            background-color: darken($color: $clr1, $amount: 2);
            height: 100%;
            border-inline-end: 1px solid darken($color: $clr1, $amount: 10);
            transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.2s;
            cursor: pointer;
            &:hover {
                background-color: darken($color: $clr1, $amount: 5);
                color: $clr2;
            }
        }
        &.SelectContent {

            border-radius: 0px !important;
            background-color: red;
        }
        &.SelectViewport {
            background-color: red;
        }
        &.SelectItem {
            background-color: red;
            outline: none;
            color: $clr2;
            border-radius: 0px;
            display: flex;
            align-items: center;
            padding: 0 $spacing-xs 0 $spacing-m;
            position: relative;
            user-select: none;
            transition: 0.2s all;
        }
    }
}
