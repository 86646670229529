@import '@radix-ui/colors/blackA.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css';

.DropdownMenuContent,
.DropdownMenuSubContent {
    z-index: 2;
    min-width: 220px;
    background-color: $clr1;
    border-radius: 6px;
    padding: $spacing-s / 2;
    box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
}
.DropdownMenuContent[data-side='top'],
.DropdownMenuSubContent[data-side='top'] {
    animation-name: slideDownAndFade;
}
.DropdownMenuContent[data-side='right'],
.DropdownMenuSubContent[data-side='right'] {
    animation-name: slideLeftAndFade;
}
.DropdownMenuContent[data-side='bottom'],
.DropdownMenuSubContent[data-side='bottom'] {
    animation-name: slideUpAndFade;
}
.DropdownMenuContent[data-side='left'],
.DropdownMenuSubContent[data-side='left'] {
    animation-name: slideRightAndFade;
}

.DropdownMenuItem,
.DropdownMenuCheckboxItem,
.DropdownMenuRadioItem,
.DropdownMenuSubTrigger {
    color: darken($clr11, 10%);
    border-radius: 3px;
    display: flex;
    align-items: center;
    padding: $spacing-xs $spacing-s;
    position: relative;
    user-select: none;
    outline: none;
}

.DropdownMenuItem[data-disabled],
.DropdownMenuCheckboxItem[data-disabled],
.DropdownMenuRadioItem[data-disabled],
.DropdownMenuSubTrigger[data-disabled] {
    color: $editor-lightgray;
    pointer-events: none;
}
.DropdownMenuItem[data-highlighted],
.DropdownMenuCheckboxItem[data-highlighted],
.DropdownMenuRadioItem[data-highlighted],
.DropdownMenuSubTrigger[data-highlighted] {
    background-color: lighten($editor-lightgray, 6%);
    color: $clr11;
}

.DropdownMenuLabel {
    padding-left: 25px;
    font-size: 12px;
    line-height: 25px;
    color: darken($editor-lightgray, 10%);
}

.DropdownMenuSeparator {
    height: 1px;
    background-color: $editor-lightgray;
    margin: $spacing-s / 2;
}

.DropdownMenuItemIndicator {
    position: absolute;
    left: 0;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.DropdownMenuArrow {
    fill: $clr1;
}

.IconButton {
    font-family: inherit;
    border-radius: 100%;
    height: 35px;
    width: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--violet11);
    background-color: $clr1;
    box-shadow: 0 2px 10px var(--blackA7);
}
.IconButton:hover {
    background-color: var(--violet3);
}
.IconButton:focus {
    box-shadow: 0 0 0 2px $clr2;
}

.RightSlot {
    margin-left: auto;
    padding-left: 20px;
    color: darken($editor-lightgray, 10%);
}
[data-highlighted] > .RightSlot {
    color: white;
}
[data-disabled] .RightSlot {
    color: $editor-lightgray;
}

@keyframes slideUpAndFade {
    from {
        opacity: 0;
        transform: translateY(2px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideRightAndFade {
    from {
        opacity: 0;
        transform: translateX(-2px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideDownAndFade {
    from {
        opacity: 0;
        transform: translateY(-2px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideLeftAndFade {
    from {
        opacity: 0;
        transform: translateX(2px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
