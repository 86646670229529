.schedule-modal {
    position: fixed;
    z-index: 17;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .send-meeting-form {
        background-color: #fff;
        padding: $spacing-m;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.226);
        border-radius: 6px;
        p {
            margin-bottom: 1em;
            color: $clr9;
        }
        & > input {
            margin-bottom: 0.5em;
        }
        button {
            margin-top: 1em;
            background-color: $editor-active-clr;
            padding: $spacing-xxs $spacing-m;
            color: $editor-active-bg;
            border-radius: 4px;
            margin-left: auto;
            display: block;
        }
    }
}
.black-overlay {
    background: rgba(0, 0, 0, 0.7);
    width: 200dvw !important;
    height: 200dvh !important;
    position: fixed;
    top: 0%;
    left: 0%;
    z-index: 15;
}
