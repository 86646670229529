.left-sidebar {
    bottom: 0;
    width: 100dvw;
    position: fixed;
    z-index: 50;

    .module-menu {
        z-index: 2;
        width: 100%;
        background-color: $clr1;
        position: relative;
        left: 0;
        transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
        .tabs {
            background-color: $clr1;
            padding: $spacing-s $spacing-m;
            display: flex;
            justify-content: center;
            align-items: center;
            .tab {
                font-size: 20px;
            }
        }

        

        
    }

    .module-content {
        pointer-events: all;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        height: 60vh;
        width: 100dvw;
        background-color: $clr1;
        transition: all 1.2s cubic-bezier(0.075, 0.82, 0.165, 1);
        &.open {
            top: -60vh;
        }
    }
    &.dragging {
        .module-content {
            top: 0;
        }
    }
}

.module-menu {
    .menu-wrapper {
        pointer-events: all;
        z-index: 2;
        background-color: $clr1;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        .scroll-indicator-wrapper {
            position: absolute;
            bottom: 0;
            height: 56px;
            width: 15%;
            z-index: 99;
            right: 0;
            pointer-events: none;
            background-color: red;
            background: linear-gradient(to right, transparent, white 80%);
        }
    }
    .menu-items {
        padding-right: 4em;
        display: flex;
        flex-direction: row;

        overflow-x: auto;
        &::-webkit-scrollbar-thumb {
            display: none;
        }
        &::-webkit-scrollbar {
            display: none;
        }
        &::-webkit-scrollbar-track {
            display: none;
        }
        flex: 1;
        &.themes {
            flex: 0;
            padding: 0;
        }
        & li {
            cursor: pointer;
            text-transform: uppercase;
            font-weight: 800;
            padding: $spacing-m $spacing-m;
            display: flex;
            align-items: center;
            border-radius: 7px;
            font-size: px-to-rem(12.44px);
            transition: background-color 0.3s, color 0.3s;
            border-radius: 0;
            &:not(:last-of-type) {
                margin-inline-end: 1em;
            }
            &.themes {
                top: 0;
                transform: translateY(-100%);
                background-color: $editor-active-clr;
                color: $clr1 !important;
                position: absolute;
                border-radius: 0;
                padding: $spacing-s $spacing-m;
                border-radius: 0 4px 0 0;
                // -webkit-background-clip: text;
                // -webkit-text-fill-color: transparent;
                width: max-content;
                font-weight: 900;
                &:hover {
                    background-color: $editor-active-clr !important;
                }
                &::before,
                &::after {
                    content: '';
                    width: 100%;
                    position: absolute;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: #03001e; /* fallback for old browsers */
                    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
                    &:hover {
                        transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
                    }
                }
                &::after {
                    z-index: -1;
                    background: -webkit-linear-gradient(
                        225deg,
                        transparent 15%,
                        #ec38bc 50%,
                        #7303c0,
                        #03001e
                    ); /* Chrome 10-25, Safari 5.1-6 */
                    background: linear-gradient(
                        225deg,
                        transparent 15%,
                        #ec38bc 50%,
                        #7303c0,
                        #03001e
                    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                    transform: translateX(0%);
                }
                &::before {
                    transform: translateX(-100%);
                    z-index: -2;
                    background: -webkit-linear-gradient(45deg, #ab48ff, #11e8b9 50%, transparent 85%);
                    background: linear-gradient(45deg, #ab48ff, #11e8bb 50%, transparent 85%);
                }
                &:hover::before {
                    transition: all 1s 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
                    transform: translateX(0%);
                }
                &:hover::after {
                    transition: all 1s 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
                    transform: translateX(-100%);
                }
            }
            &:hover:not(.active) {
                background-color: $editor-hover-bg;
            }
            &.active {
                color: $editor-active-clr;
                background-color: $editor-active-bg;
            }
        }
    }
}

.right-sidebar {
    transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
    background-color: $clr1;
    z-index: 50;
    overflow: hidden;
    position: fixed;
    bottom: 55px;
    width: 100dvw;
    transform: translateY(100%);
    &.open {
        transform: translateY(0%);
    }
    .module-content {
        min-height: 0;
        .TabsRoot {
            max-height: 40vh;
            overflow-y: auto;
        }
    }
}
.empty-right-sidebar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: $spacing-xxl;
    img {
        max-width: 106px;
        margin-bottom: 1rem;
    }
}

.module-content {
    pointer-events: all;
    position: relative;
    .module-options {
        height: 100%;
        overflow-y: auto;
        padding: $spacing-m $spacing-m $spacing-l;
        &::-webkit-scrollbar-thumb {
            border: 3.5px solid transparent;
            border-radius: 100px;
            background-color: $clr9;
            background-clip: content-box;
        }
        &::-webkit-scrollbar {
            width: 12px;
        }
        &::-webkit-scrollbar-track {
            background-color: $clr12;
        }
    }
    .module-header {
        padding: 1em $spacing-m;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $editor-lightgray;
        .module-name {
            font-weight: 800;
            text-transform: uppercase;
        }
        .close-btn {
            cursor: pointer;
            font-size: 1em;
        }
    }
    .themes-intro {
        color: $clr7;
        margin-bottom: $spacing-s;
    }
}
.theme-container {
    $clr1-5d: darken($clr1, 5%);
    background-color: darken($clr1, 2%);
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
    margin-bottom: $spacing-s;
    border: 1px solid darken($clr1, 10%);
    transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
    &:hover {
        transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
        background-color: $clr1-5d;
        box-shadow: inset 0 0 0 3px rgba($clr1-5d, 50%);
        h5 {
            color: $clr2;
        }
    }
    .theme-header {
        padding: $spacing-s;
        h5 {
            color: $clr11;
            font-size: 16px;
        }
        .theme-desc {
            color: #858585;
            font-size: 0.8em;
        }
    }
    .theme-colors-container {
        display: flex;
        & > * {
            flex-grow: 1;
        }
        & > :first-child {
            flex-grow: 3;
        }
        & > :nth-child(2) {
            flex-grow: 2;
        }
    }
}

@keyframes hide {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (min-width: 560px) {
    .right-sidebar {
        max-width: max-content;
        box-shadow: 0px 0px 20px -10px;
        border-radius: 1rem 0 0 0;
        right: 0;
        > * {
            max-width: 330px;
        }
    }
}
@media (min-width: 960px) {
    .right-sidebar {
        position: relative;
        width: $right-sidebar-width;
        margin-right: -$right-sidebar-width;
        height: 100%;
        bottom: 0;
        opacity: 0;
        transition: 0.6s margin cubic-bezier(0.075, 0.82, 0.165, 1),
            0.5s opacity 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
        max-width: max-content;
        box-shadow: none;
        border-radius: 0px;
        transform: translateY(0%);
        .module-content .TabsRoot {
            max-height: calc(100vh - $app-header-height - $toolbar-height - 50px);
        }
        &.open {
            margin: 0;
            opacity: 1;
            // left: 300px;
            transform: translateX(0%);
        }
    }
    .left-sidebar {
        min-height: calc(100vh - $app-header-height + $toolbar-height);
        pointer-events: none;
        bottom: unset;
        transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        overflow: visible;
        display: flex;
        max-width: $modules-menu-width;
        left: 0;
        transform: translateX(-100%);
        .indicator {
            z-index: -5;
            pointer-events: all;
            position: absolute;
            top: 0;
            right: 0;
            left: unset;
            bottom: 0;
            margin: auto;
            cursor: pointer;
            height: max-content;
            padding: $spacing-xs $spacing-xxs;
            color: $editor-active-bg;
            font-size: px-to-rem(20px);
            line-height: 1;
            animation: show 1200ms ease-in;
            transform: translate(100%, -90px);
            border-radius: 0 55rem 55rem 0;
            background-color: $clr7;
            opacity: 1;
            transition: opacity 1s cubic-bezier(0.075, 0.82, 0.165, 1),
                transform 0.6s 1s cubic-bezier(0.075, 0.82, 0.165, 1);
            &::before {
                z-index: -2;
                content: '';
                position: absolute;
                top: 0;
                right: unset;
                left: 0;
                bottom: 0;
                margin: auto;
                border-radius: 0 0.55rem 0.55rem 0;
                width: 10px;
                height: $spacing-xxl;
                background-color: $clr7;
            }
        }
        &.open .indicator {
            transition: opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1),
                transform 0.6s 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
            transform: translate(-15%, -90px);
            opacity: 0;
            z-index: 1;
        }
        .scroll-indicator-wrapper {
            display: none;
            .scroll-indicator {
                display: none;
            }
        }
        &.dragging {
            transform: translateX(calc(-100% - $modules-content-width)) !important;
        }
        &.open {
            pointer-events: all;
            transform: translateX(0%);
        }
        .module-menu {
            flex: unset;
            min-height: calc(100vh - $app-header-height + $toolbar-height);
            .menu-wrapper {
                flex-direction: column;
                border-right: 1px solid $editor-lightgray;
                .menu-items {
                    box-shadow: none;
                    padding: $spacing-s 0;
                    border-bottom: 1px solid $editor-lightgray;
                    flex-direction: column;
                    flex: unset;
                    &.themes {
                        flex: 1;
                    }
                    & li {
                        padding: $spacing-xs $spacing-m;
                        letter-spacing: 0.025rem;
                        border-radius: 0;
                        margin-inline-start: 0em;
                        margin-inline-end: 0em;
                        width: 100%;
                        &.themes {
                            position: relative;
                            top: unset;
                            transform: translateY(0%);
                            background-color: inherit;
                            color: inherit;
                            background-color: transparent;
                            &:hover {
                                background-color: transparent !important;
                            }
                        }
                    }
                }
            }
        }
        .module-content {
            width: $modules-content-width;
            transform: translateX(-$modules-menu-width);
            height: calc(100vh - $app-header-height - $toolbar-height - 1px);
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.192);
            .quick-add {
                > div {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 2em;
                }
                img {
                    border-radius: 0.15rem;
                    box-shadow: 0px 5px 7px -2px rgba($clr9, 30%);
                }
            }
            &.open {
                top: unset;
                transition: all 0.75s cubic-bezier(0.16, 1, 0.3, 1);
            }
        }
        & .module-content.open {
            transform: translateX($modules-menu-width);
        }
    }
}
