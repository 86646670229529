@import '@radix-ui/colors/mauve.css';

/* reset */

.HoverCardContent {
    $clrdarken: darken($clr1, 8%);
    z-index: 99999;
    background-color: $clr1;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    border-radius: 6px;
    padding: $spacing-m $spacing-m 0 $spacing-m;
    width: 300px;
    will-change: transform, opacity;
    .br-b {
        padding-bottom: 0.5em;
        border-bottom: 1px solid rgba($clr2, 10%);
        margin-top: 1em;
    }
    max-width: 300px;
    .avatar {
        cursor: default;
        width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 55px;
        overflow: hidden;
        color: $clr1;
        text-transform: uppercase;
        background-color: scale-color($color: #055345, $saturation: -20%, $lightness: -5%);
        transition: 1s ease-out box-shadow;
        &:hover {
            transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) box-shadow;
            box-shadow: 0px 0px 0px 2.5px lighten($editor-active-clr, 5%);
        }
    }
    .bold-link {
        font-weight: 600;
        color: $editor-active-clr;
        margin: $spacing-s 0;
    }
    &.guest {
        padding: 1em $spacing-m;
        .login-signup-guest {
            display: flex;
            gap: 5px;
        }
        .btn-secondary {
            border: 1px solid rgba($editor-active-clr, 66%);
            color: $editor-active-clr;
            padding: 2px 6px;
            border-radius: 4px;
            cursor: pointer;
            transition: color 0.4s cubic-bezier(0.075, 0.82, 0.165, 1),
                background-color 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
            &:hover {
                color: darken($editor-active-clr, 6%);
                background-color: $editor-active-bg;
            }
        }
    }

    .HoverCardContent[data-side='top'] {
        animation-name: slideDownAndFade;
    }
    .HoverCardContent[data-side='right'] {
        animation-name: slideLeftAndFade;
    }
    .HoverCardContent[data-side='bottom'] {
        animation-name: slideUpAndFade;
    }
    .HoverCardContent[data-side='left'] {
        animation-name: slideRightAndFade;
    }

    .HoverCardArrow {
        fill: $clr1;
    }

    .ImageTrigger {
        cursor: pointer;
        border-radius: 100%;
        display: inline-block;
    }
    .ImageTrigger:focus {
        box-shadow: 0 0 0 2px $clr1;
    }

    .Image {
        display: block;
        border-radius: 100%;
    }
    .Image.normal {
        width: 45px;
        height: 45px;
    }
    .Image.large {
        width: 60px;
        height: 60px;
    }

    .Text {
        color: var(--mauve12);
        font-size: 15px;
        line-height: 1.5;
    }
    .Text.faded {
        color: var(--mauve10);
    }
    .Text.bold {
        font-weight: 500;
    }
    .logout-icon {
        position: absolute;
        top: $spacing-s;
        right: $spacing-s;
        color: darken($editor-lightgray, 20%);
        &:hover {
            color: darken($editor-lightgray, 50%);
        }
    }
    .btn-primary {
        background-color: $editor-active-clr;
        color: $editor-active-bg;
        padding: 2px 6px;
        border-radius: 4px;
        cursor: pointer;
        transition: color 0.4s cubic-bezier(0.075, 0.82, 0.165, 1),
            background-color 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
        border: 1px solid darken($editor-active-clr, 6%);
        &:hover {
            color: $editor-active-bg;
            background-color: darken($editor-active-clr, 6%);
        }
    }
    .user-img {
        display: block;
        border-radius: 100%;
    }
    .user-img.normal {
        width: 45px;
        height: 45px;
    }
    .user-img.large {
        width: 60px;
        height: 60px;
    }
    
}
