/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
    box-sizing: border-box;
}

/*
  2. Remove default margin
*/
* {
    margin: 0;
}
label,
input,
button {
    display: inline-flex;
    align-items: center;
}

/*
  3. Allow percentage-based heights in the application
*/
html,
body {
    height: 100%;
    overflow-x: hidden;
}

textarea {
    resize: none;
}

svg {
    vertical-align: middle;
}

img,
picture,
video,
svg,
canvas {
    vertical-align: middle;
    display: block;
    max-width: 100%;
}

svg {
    vertical-align: middle;
    min-height: 100%;
    box-sizing: border-box;
    display: revert;
}

a {
    text-decoration: none;
    transition: color 0.3s;
    font-family: $font-family;
    color: $clr2;
    font-weight: 800;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select,
a {
    font: inherit;
}
button {
    font-weight: 600;
}
/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}

[contenteditable]:focus {
    outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
