.editor-preview {
    $editor-max-height: calc(100vh - $app-header-height - $toolbar-height - 10px);
    min-height: 100%;
    padding: 15px 10px;
    background-color: darken($editor-hover-bg, 5%);
    box-shadow: inset 0px 0px 20px -10px rgba($editor-outline, 50%);
    overflow-y: auto;
    overflow-x: hidden;

    .editor-wrapper {
        margin: auto;
        box-shadow: 0px -5px 15px 20px rgba($clr7, 0.125);
        border-radius: $spacing-s;
        overflow: hidden;
        position: relative;
        transition: max-width 0.5s cubic-bezier(0.83, 0.22, 0.17, 0.84);
        min-height: 100%;
        background: white;
    }

    &::-webkit-scrollbar-thumb {
        border: 6px solid transparent;
        border-radius: 100px;
        background-color: $clr9;
        background-clip: content-box;
    }
    &::-webkit-scrollbar {
        width: $scroll-bar-width;
    }
    &::-webkit-scrollbar-track {
        background-color: $clr12;
    }
}

.editor-resizer {
    $scroll-bar-width: $spacing-m;
    z-index: 3;
    position: fixed;
    top: 50%;
    height: 3rem;
    width: 10px;
    border-radius: 10em;
    cursor: e-resize;
    background-color: $clr9;
    &.left {
        transform: translate(-100%, 100%);
        left: 35px;
    }
    &.right {
        transform: translate(100%, 100%);
        right: calc(35px + $scroll-bar-width);
    }
}

.mobile-layout {
    overflow-y: auto !important;
    box-sizing: border-box;
    height: calc(100vh - $app-header-height - $toolbar-height - 3.1em) !important;
    border: 6px solid #f7f7f7;
    border-radius: 40px !important;
    box-shadow: #ffffff1a 0 1px 1px inset, #32325d40 0 50px 100px -20px, #0000004d 0 30px 60px -30px;
}
.tablet-layout {
    overflow-y: auto !important;
    box-sizing: border-box;
    height: calc(100vh - $app-header-height - $toolbar-height - 3.1em) !important;
    border: 6px solid #f7f7f7;
    border-radius: 40px !important;
    box-shadow: #ffffff1a 0 1px 1px inset, #32325d40 0 50px 100px -20px, #0000004d 0 30px 60px -30px;
}

.mobile-layout::-webkit-scrollbar,
.tablet-layout::-webkit-scrollbar {
    display: none;
}
@media (min-width: 960px) {
    .editor-layout .editor-preview {
        padding: $spacing-m $spacing-l;
    }
}
