.login-signup {
    font-size: px-to-rem(16px);
    background-color: $clr2;
    color: $login-gray;
    min-height: calc(100vh - $app-header-height);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .header {
        margin-bottom: $spacing-m;
    }
    h2,
    h1 {
        font-size: px-to-rem(81px);
        line-height: 1.5;
        color: $clr1;
    }
    form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        button {
            cursor: pointer;
            margin-top: 1em;
            background-color: $clr1;
            color: $editor-active-clr;
            padding: 0.4em 1.8em;
            font-weight: 600;
            border-radius: 55em;
            font-size: px-to-rem(20px);
            transition: background-color 0.3s, color 0.3s, background 0.3s, box-shadow 0.1s ease-in-out;
            border: 1px solid $editor-active-clr;
            &:hover {
                color: $clr1;
                background-color: $editor-active-clr;
            }
        }
    }
    .login-options-container {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: start;
        width: 100%;
        

        .seperetor {
            display: flex;
            flex-direction: column;
            margin: 0 px-to-em(69px);
            
            .divider {
                width: 1px;
                min-height: 100px;
                height: 100%;
                background-color: $login-gray;
                margin: auto;
            }
        }
        margin: 2em 0;
    }
    input {
        font-size: 18px;
        margin-bottom: 1em;
        background-color: transparent;
        color: $clr1;
        border-bottom: 1px solid $clr1;
        border-radius: 0;
        transition: background-color 600000s 0s, color 600000s 0s, 0.3s border ease-in-out;
        &:focus {
            background-color: transparent;
            border-bottom: 1px solid $editor-active-clr;
            transition: background-color 600000s 0s, color 600000s 0s, 0.3s border ease-in-out;
        }
    }
    .toggle-link {
        color: $editor-active-clr;
        cursor: pointer;
    }

    
    
    .api-login {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1em;
        > button {
            font-size: 16px !important;
            letter-spacing: 0 !important;
            width: 100%;
            display: grid;
            grid-template-columns: auto 1fr;
            background-color: #4285f4 !important;
            color: white !important;
            > div {
                margin: 0;
            }
            span {
                flex: 1;
                margin: auto;
            }
        }
    }

    .footer {
        text-align: center;
        .recaptcha {
            p:first-of-type {
                margin-bottom: 0.5em;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1em;
            }
            width: 100%;
            a {
                color: $login-gray;
                text-decoration: underline;
            }
            width: 100%;
        }
        .links {
            width: 100%;
        }
    }
}
