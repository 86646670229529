// List
.clean-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

// Text
.vertical-text {
    writing-mode: tb-rl;
    transform: rotate(-180deg);
}

.tag-text {
    color: $clr1;
    text-transform: uppercase;
    @include type(boldCondensed);
}

// Inline space
.space-inline-s {
    & > *:not(:last-child) {
        margin-inline-end: 1em;
    }
}

.space-inline-m {
    & > *:not(:last-child) {
        margin-inline-end: 2em;
    }
}

.space-inline-l {
    & > *:not(:last-child) {
        margin-inline-end: 3em;
    }
}

// Aspect ratios

.ratio-16-9 {
    @include aspect-ratio(100, 56);
}

.ratio-square {
    @include aspect-ratio(100, 100);
}

.ratio-card {
    @include aspect-ratio(100, 80);
}

.ratio-poster {
    @include aspect-ratio(100, 150);
}

.flex {
    display: flex;

    &.column {
        flex-direction: column;
    }

    &.flex-center {
        align-items: center;
        justify-content: center;
    }

    &.justify-between {
        justify-content: space-between;
    }

    &.justify-center {
        justify-content: center;
    }

    &.justify-start {
        justify-content: flex-start;
    }

    &.justify-end {
        justify-content: flex-end;
    }

    &.align-center {
        align-items: center;
    }
}
.hidden {
    display: none !important;
}

.cursor-pointer {
    cursor: pointer;
}
