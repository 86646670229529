.wap-index {
    background: #f7f7f7;
    min-height: calc(100vh - $app-header-height - 1px);
    .intro {
        background: inherit;
        background: #f7f7f7;
        position: sticky;
        top: $app-header-height + 1px;
        z-index: 1;

        .wrapper {
            padding: $spacing-m 0;
            display: flex;
            align-items: center;

            h1 {
                font-size: 1.5rem;
                line-height: 1.125;
            }
            @media (min-width: $break-mobile) {
                h1 {
                    font-size: 1.75rem;
                }
            }
            @media (min-width: $break-narrow) {
                h1 {
                    font-size: 2rem;
                }
            }
        }
    }

    .templates {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-flow: dense;
        padding: px-to-em(80px) 0;
        padding-top: $spacing-m / 2;
        gap: 3rem 0;

        @media (min-width: $break-narrow) {
            & {
                grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
                gap: 5.25em 3em;
            }
        }
        article.template {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .img-container {
                transition: 1s box-shadow cubic-bezier(0.075, 0.82, 0.165, 1);
                position: relative;
                box-shadow: 0px 5px 6px 0px rgba($clr7, 0.1);
                border-radius: 0.5rem;
                overflow: hidden;
                width: 100%;
                aspect-ratio: 16 / 9;

                &:hover {
                    box-shadow: 0px 0px 0px 0px rgba($clr7, 0.1);
                    transition: 1s box-shadow cubic-bezier(0.075, 0.82, 0.165, 1);
                }
                img {
                    width: 100%;
                    height: 100%;
                }

                &.blank-template {
                    aspect-ratio: 16 / 9;
                    cursor: pointer;

                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
                    color: rgba($clr1, 90%);
                    background-color: $clr2;
                    &:hover {
                        color: rgba($clr1, 90%);
                        background-color: $clr2;
                    }
                    & > * {
                        transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1);
                    }
                    &:hover > * {
                        border-radius: 55em;
                        box-shadow: 0px 0px 15px 5px rgba($clr9, 20%), 0px 0px 50px 5px rgba($clr9, 50%),
                            0px 0px 0px 1px rgba($clr1, 20%), inset 5px -5px 10px -5px $clr1,
                            inset -5px 5px 20px -10px rgba($clr1, 50%);
                        transform: rotate(180deg) scale(1.5);
                    }
                }

                .actions {
                    text-align: center;
                    position: absolute;
                    inset: 0;
                    margin: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    z-index: 1;
                    opacity: 0;
                    transition: opacity 0.3s;
                    & .btn {
                        cursor: pointer;
                        font-size: 16px;
                        padding: 2px $spacing-m;
                        transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
                    }
                }

                &:not(.blank-template)::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba($clr2, 85%);
                    opacity: 0;
                    transition: opacity 0.4s cubic-bezier(0.075, 0.82, 0.165, 1),
                        backdrop-filter 0.2s 0.05s cubic-bezier(0.165, 0.84, 0.44, 1);
                    min-width: 100%;
                    pointer-events: none;
                }

                &:hover::after {
                    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
                    opacity: 1;
                    pointer-events: all;
                    backdrop-filter: blur(3px);
                }

                &:hover:not(.blank-template) .actions {
                    transition: opacity 0.4s 0.2s;
                    opacity: 1;
                }
            }

            .text-container {
                margin-top: 1rem;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
            }
        }
    }
    @media (min-width: 1000px) {
    }
}
